<template>
  <div>
    <ContractForm
      :contract="contract"
      @clear="clearForm"
    />
    <ArtistText
      :contract="contract"
      :company="company"
      :companyObject="companyObject"
    />
  </div>
</template>

<script>
import viewsMixin from '@/mixins/views-mixin';
import ContractForm from '@/components/ContractForm.vue';
import ArtistText from '@/components/ArtistInvoice.vue';

export default {
  name: 'Artist',
  components: {
    ContractForm,
    ArtistText,
  },
  mixins: [viewsMixin],
};
</script>
